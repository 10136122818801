<template>
  <div title='會員條款' name='support' key='0'>
    <div class='row d-flex justify-content-between align-items-center' style='margin:20px 20px 0px'>
      <div style='font-size:20px'>{{getTitle()}}</div>
        <div class="align-right">
          <button type="button" @click="edit()" v-show='!is_edit && !is_new' class="btn btn-fill btn-info"  v-if="checkScope(['policy:u'])">編輯</button>
          <button type="button" @click="editCancel()" v-show='is_edit && !is_new' class="btn btn-fill btn-info" style='margin-right: 10px;'>取消</button>
          <button type="submit" @click.prevent="validate" v-show='is_edit || is_new' class="btn btn-fill btn-info" >儲存</button>
      </div>
    </div>
    <br/>
    <fg-input label="" :error="getError(`content`)" required>
      <el-input type="textarea" name="content" v-model="form.content" rows="8"  data-vv-as="條款內容"  v-validate="'required|max:6800'" v-bind:disabled="!is_edit" ></el-input>
    </fg-input>
  </div>
</template>
<script>
 /* eslint-disable */ 
  // import { Dialog } from 'element-ui'
  import lib from '../../../lib'
  import tools from '../../../tools'
  import swal from 'sweetalert2/dist/sweetalert2.js'

  export default {
    components: {
    },
    data () {
      return {
        form:{
          content: null
        },
        origin: null,
        is_edit: false,
        is_new: false,
      }
    },
    async mounted () {
      await this.getData()
    },
    watch:{
    },
    methods: {
      getTitle(){
        let title = ''
        if (this.is_new){
          title = '新增會員條款'
        }else{
          title = '會員條款'
        }
        return title
      },
      edit(){
        this.origin = JSON.parse(JSON.stringify(this.form))
        this.is_edit = true
      }, 
      editCancel(){
        this.form = JSON.parse(JSON.stringify(this.origin))
        this.is_edit = false
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      async getData () {
        var _this = this
        var result = await lib.getPolicy()
        if (result.code == 0){
          var data = result.data
          if (data){
            this.form = data
          }else{
            this.is_new = true
            this.is_edit = true
          }
        }
      },
      validate () {
        var _this = this
        this.$validator.validateAll().then(isValid => {
          if (!isValid) return;
          _this.update()
        })
      },
      async update(){
        var result = await lib.updatePolicy(this.form);
        if (result.code == 0){
          this.is_edit = false
          this.is_new = false
          swal('已更新', '', 'success')
        }else {
          swal('更新失敗', result.data, 'error')
        }
      }
    }
  }
</script>