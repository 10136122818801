<template>
  <div>

    <div class='row d-flex justify-content-between align-items-center' style='margin:20px 20px 0px'>
      <div style='font-size:20px'>權限設定</div>
        <div class="align-right">
          <button type="button" @click="handleUpdate()"  class="btn btn-fill btn-large btn-info"  v-if="checkScope(['permission:u'])" >儲存</button>
      </div>
    </div>
      <div class="row">
        <div class="col-sm-12">
            <div :style="getTableContainer()">
                <span class='tableHeader'></span>
                <span class='tableHeader'></span>
                <template v-for="(r, ridx) in roles">
                 
                  <span class='tableHeader' v-bind:key="'roleHeader' + ridx"><label style='font-size:14px;'>{{r.text}}</label></span>
                </template>
                <template v-for="(i, idxi) in permissionData" >
                  <span :key="'title' + idxi" class='tableCell'>{{i.title}}</span>
                   <template v-for="(j,idxJ) in i.subMunu">
                     <span class='' v-if='idxJ > 0' v-bind:key="'empty' + idxi + idxJ"></span>
                     <span class='tableCell' v-bind:key='"subMenu" + idxi + idxJ'>{{j.title}}</span>
                     <template v-for="(r, ridx) in roles">
                       <span  class='tableCell tableContrller' v-bind:key='"roleCon" + idxi + idxJ + ridx' >
                         
                        <div class="form-check new-label-word" >
                          新增
                          <label class="form-check-label"> 
                            <input type="checkbox" class="form-check-input" v-model="form.options[`${r.id}`]" :value="`${j.key}:c`" :disabled="j.disable && j.disable.includes('c')">
                            <span class="form-check-sign"></span>
                          </label>
                        </div>

                        <div class="form-check new-label-word" >
                          瀏覽
                          <label class="form-check-label"> 
                            <input type="checkbox" class="form-check-input" v-model="form.options[`${r.id}`]" :value="`${j.key}:r`">
                            <span class="form-check-sign"></span>
                          </label>
                        </div>   

                        <div class="form-check new-label-word" >
                          編輯
                          <label class="form-check-label"> 
                            <input type="checkbox" class="form-check-input" v-model="form.options[`${r.id}`]" :value="`${j.key}:u`">
                            <span class="form-check-sign"></span>
                          </label>
                        </div>   

                        <div class="form-check new-label-word" >
                          刪除
                          <label class="form-check-label"> 
                            <input type="checkbox" class="form-check-input" v-model="form.options[`${r.id}`]" :value="`${j.key}:d`" :disabled="j.disable && j.disable.includes('d')">
                            <span class="form-check-sign"></span>
                          </label>
                        </div>                        

                       </span>
                     </template>
                  </template>
                </template>
            </div>
            {{form.options['a2']}}<br/>
            {{form.options['a3']}}<br/>
            {{form.options['a4']}}
            <!-- <div v-for="i in permissionData" v-bind:key="'main' + i">
                {{i.title}}
              <div v-for="j in i.subMunu" v-bind:key="'subMunu' + j">
                {{j.title}}
              </div>
            </div> -->
        </div>
      </div>
  </div>
</template>
<script>
 /* eslint-disable */ 

  import { Checkbox } from 'src/components/index'
  import lib from '../../../lib'
  import tools from '../../../tools'
  import swal from 'sweetalert2/dist/sweetalert2.js'

  export default {
    components: {
      Checkbox,
    },
    data () {
      return {
        permissionData:[
          {
            title: "管理者設定",
            subMunu:[
              { key: "user", title: "後台管理者"},
              { key: "organization", title: "分公司/部門設定"},
              { key: "permission", title: "權限設定", disable:['c','d']},
            ]
          },
          {
            title: "會員資訊",
            subMunu:[
              { key: "member", title: "會員資訊"},
            ]
          },
          {
            title: "活動資訊",
            subMunu:[
              { key: "activity", title: "活動設定"},
            ]
          },
          {
            title: "一般資訊",
            subMunu:[
              { key: "policy", title: "會員條款", disable:['c','d']},
              { key: "support", title: "客戶服務", disable:['c','d']},
            ]
          }
        ],
        form:{
          options: {
            "2":[],
            "3":[],
            "4":[]
          }

        },
        roles:[
          {id: 2, text: 'Admin'},
          {id: 3, text: 'Manager'},
          {id: 4, text: 'Assistant'}
        ]
      }
    },
   async mounted () {
    var _this = this
    await this.getData()
    },
    methods: {
      getTableContainer(){
        let css = {}
        css.display = "grid"
        css.widht = "80%"
        css['grid-template-columns'] = `100px 150px repeat(${this.roles.length}, 1fr)`;
        return css
      },
      handleUpdate () {
        var _this = this
        var promises = []
        this.roles.map(async function(role){
          let id = role.id
          let permissions = _this.form.options[id]
          let data = {
            permissions: permissions
          }
          promises.push(lib.updateRole(id, data))
        })
        Promise.all(promises).then(function(values) {
          let errors = values.filter(el => el.code !== 0)
          if (errors.length == 0){
            swal('已更新', '', 'success')
          }else{
            swal('更新失敗', '', 'error')
          }
        });
      },
      async getData () {
        let result = await lib.getRoleList()
        let _this = this
        if (result.code == 0){
          let data = result.data
          this.roles.map(function(role){
           let id = role.id
           let row = data.find(el => el.id == id)
            _this.form.options[`${id}`] = row.permissions
          })
        }
      }
    }
  }
</script>
<style>
.tableHeader{
  border-bottom: 1px #EBEEF5 solid;
  justify-self: center;

}

.tableCell{
  display: flex;
  align-items: center;
  height: 50px;
  border-top: 1px #EBEEF5 solid;
  /* justify-content: space-around; */ 
}

.new-label-word{
  font-size: 12px;
}

.new-label-word > .form-check-label{
  padding-left: 0;
}

.tableContrller {
  justify-content: space-around;
  padding: 0 10px;
}
</style>
