<template>
  <div>
    <div v-show='!is_edit_page'>
    <div class='row d-flex justify-content-between align-items-center' style='margin:20px 20px 0px'>
      <div style='font-size:20px' class='d-flex'>會員管理&nbsp;&nbsp;&nbsp;
        <el-select class="select-default " style='margin:0 10px 0 50px;width:100px;' @change="handleChange" v-model="query.search_type" :close-transition="true" clearable >
          <el-option value='email' label="E-mail" />
          <el-option value='phone_number'  label="手機" />
          <el-option value='given_name'  label="名" />
          <el-option value='family_name'  label="姓" />
          <el-option value='preferred_username' label="身份" />
          <el-option value='status' label="狀態"></el-option>   
        </el-select>

        <el-select class="select-default " v-if="query.search_type=='phone_number'" style='cursor: pointer;width:260px;margin-right:10px;' placeholder="請選擇" name="phone_number_1" v-model="query.countryCode"  >
          <el-option  v-for="phone in phoneData" class="select-default"  v-bind:key="phone.code" :value="`${getCountryCodeForRegionCode(phone.code)}`"  :label="phone.name + ' (+'+ getCountryCodeForRegionCode(phone.code) + ')'"></el-option>
        </el-select>
        <el-select v-if="query.search_type=='status'" class="select-default " style='height:30px;margin-right:10px;width:150px;' v-model="query.search_text">
          <el-option value='Enabled' label="啟用" />
          <el-option value='Disabled'  label="停用" />
        </el-select>
          <fg-input  v-if="query.search_type !='status'"
          v-model="query.search_text"
          type="text"
          style='height:30px;margin-right:10px;'
          >
          </fg-input>&nbsp;
          <button type="button" @click="getData()"  class="btn btn-fill btn-large btn-info" >搜尋</button>
      </div>

        <div class="align-right">
          <button type="button" @click="handleNew()"  class="btn btn-fill btn-large btn-info" v-if="checkScope(['member:c'])" >新增</button>
      </div>
    </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table :data="pagedData" >

            <el-table-column prop="phone_number"
                             label="手機"
                             >
              <div slot-scope="props" >
                             <a href="javascript:void(0)" @click="handleEdit(props.row)" >{{getPhoneNumber(props.row)}}</a>
                </div>
            </el-table-column>
            <el-table-column prop="nickname"
                             label="姓名">
              <div class="td-actions" slot-scope="props" >
                {{props.row.family_name}}&nbsp;{{props.row.given_name}}
              </div>
            </el-table-column>
            <el-table-column prop="email"
                             label="E-mail" 
                             >
            </el-table-column>
            <el-table-column prop="UserStatus" 
                  label="註冊狀態">
            </el-table-column>
            <el-table-column prop="Enabled" 
                  :formatter="getEnableText"
                  label="狀態">
            </el-table-column>

          </el-table>
        </div>
      </div>
        <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <div class="">
            <p class="card-category"></p>
          </div>
          <l-pagination class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :isMore="(PaginationToken != null)"
                  :per-page="pagination.perPage"
                  :total="pagination.total"
                  @loadMore="getData(true)"/>
        </div>
    </div>
    <div v-if='is_edit_page'>
    <div class='row d-flex justify-content-between align-items-center' style='margin:20px 20px 0px'>
      <div style='font-size:20px'>{{getTitle}}</div>
      <div class="align-right">
        <a href="#" @click.prevent="is_edit_page=false;editCancel()" class="" style='margin-right: 40px;font-size:14px;' >&lt;&lt;&lt;&nbsp;返回列表</a>
        <button type="button" @click="edit()" v-show='!is_edit && !is_new' class="btn btn-fill btn-info" v-if="checkScope(['member:u'])" >編輯</button>
        <button type="button" @click="editCancel()" v-show='is_edit && Boolean(form.sub)' class="btn btn-fill btn-info" style='margin-right: 10px;'>取消</button>
        <button type="submit" @click.prevent="validate" v-show='is_edit' class="btn btn-fill btn-info" >{{getSubmitText()}}</button>
      </div>
    </div>
      <div class="col-sm-12 row" style="margin-top:40px">
        <div class="col-sm-6 " style='padding:0'>
          <div style='font-size:18px'>資料</div>
          <hr>
          <div class="has-label" style='margin-bottom: 1rem;'>
            <label> 身分</label>
            <template v-if="!Boolean(form.sub)">
            <div class='d-flex row' >
              <el-select class="select-default  col-sm-4 " style='cursor: pointer;width:100px;margin-left:0;' placeholder="請選擇" name="preferred_username_2" v-model="preferred_username_2"  v-bind:disabled="Boolean(form.sub)" >
                <el-option class="select-default" value="1" label="本國人" key="1"  ></el-option>
                <el-option class="select-default" value="2" label="外國人" key="2"  ></el-option>
              </el-select>
              <fg-input 
                      type="text"
                      name="preferred_username_1"
                      data-vv-as="身分"
                      class='col-sm-8'
                      style='padding-left:0;margin-bottom: 0px;'
                      v-model="preferred_username_1"   
                      v-validate="getIdRule"
                      v-bind:disabled="form.sub !=null"
                      required />
            </div>
            <div class='text-danger invalid-feedback' style="display:block" v-show="errors.firstByRule('preferred_username_1', 'required') || errors.firstByRule('preferred_username_2', 'required')">身分 不能為空</div>
            <div class='text-danger invalid-feedback' style="display:block" v-show="errors.firstByRule('preferred_username_1', 'checkTwId')" >身分證 有誤{{errors.firstRule('checkTwId')}}</div>
            </template>
            <template v-else >
              <div>
                  {{form.preferred_username}}
              </div>
            </template>
          </div>
          <fg-input label="E-mail"
                type="text"
                name="email"
                data-vv-as="E-mail"
                class=''
                v-model="form.email"   
                v-validate="{ email: true }"
                :error="getError('email')"
                v-bind:disabled="!is_edit"
                >
            </fg-input>
          <div class="has-label" style='margin-bottom: 1rem;'>
            <label> 手機</label>
            <div class='d-flex row'>
              <el-select class="select-default col-sm-6" style='cursor: pointer;margin-left:0;' placeholder="請選擇" name="phone_number_1" v-model="phone_number_1"  v-validate="'required'" v-bind:disabled="Boolean(form.sub)" >
                <el-option  v-for="phone in phoneData" class="select-default"  v-bind:key="phone.code" :value="`${getCountryCodeForRegionCode(phone.code)}`"  :label="phone.name + ' (+'+ getCountryCodeForRegionCode(phone.code) + ')'"></el-option>
              </el-select>
              <fg-input 
                      type="text"
                      name="phone_number_2"
                      class='col-sm-6'
                      style='padding-left:0;margin-bottom: 0px;'
                      v-model="phone_number_2"   
                      v-validate="{ required: true }"
                      v-bind:disabled="form.sub !=null"
                     />
            </div>
            <input v-model="check_phone_number" name='check_phone_number' v-validate="Boolean(!form.sub)?{ checkPhone: true }:{}" hidden />
            <div class='text-danger invalid-feedback' style="display:block" v-show="errors.has('phone_number_1') || errors.has('phone_number_2')">手機 不能為空</div>
            <div class='text-danger invalid-feedback' style="display:block" v-show="!(errors.has('phone_number_1') || errors.has('phone_number_2')) && errors.firstByRule('check_phone_number', 'checkPhone')">手機 格式錯誤</div>
          </div>
          <fg-input label="姓"
                type="text"
                name="family_name"
                data-vv-as="姓"
                class=''
                v-model="form.family_name"
                v-bind:disabled="!is_edit"
                >
            </fg-input>
          <fg-input label="名"
                type="text"
                name="given_name"
                data-vv-as="名"
                class=''
                v-model="form.given_name"
                v-bind:disabled="!is_edit"
                >
            </fg-input>

          <fg-input label="暱稱"
                type="text"
                name="nickname"
                data-vv-as="暱稱"
                class=''
                v-model="form.nickname"
                v-bind:disabled="!is_edit"
            >
            </fg-input>
          <div class=" has-label" style='margin-bottom: 1rem;'>
            <label>性別</label>
            <div >
            <el-select class="select-default " style='cursor: pointer;width:100%;' data-vv-as="性別" placeholder="請選擇" name="gender" v-model="form.gender"  v-bind:disabled="!is_edit" >
              <el-option class="select-default" value="0" label="男" :key="0"  ></el-option>
              <el-option class="select-default" value="1" label="女" :key="1"  ></el-option>
            </el-select>
            </div>
          </div>
          <div class=" has-label" style='margin-bottom: 1rem;'>
            <label> 生日</label>
            <el-date-picker v-model="form.birthdate" type="date" value-format="yyyy-MM-dd"  v-bind:disabled="!is_edit" > </el-date-picker>
          </div>
        
          <fg-input label="社群"
                type="text"
                name="mobile"
                class=''
                v-model="form['custom:fb_id']"
                v-bind:disabled="!is_edit"
                >
            </fg-input>
        </div>
        <div class="col-sm-6">
          <div style='font-size:18px'>密碼設定</div>
          <hr>
          <fg-input label="密碼設定"
                type="password"
                value='***************'
                disabled
                v-show='!is_edit'
                style='margin-bottom: 209px;'
                required>
          </fg-input>
          <fg-input label="密碼設定"
                type="password"
                name="password"
                data-vv-as="密碼設定"
                class=''
                v-model="form.password"   
                v-validate="getPasswordRule"
                ref="password"
                :error="getError('password')"
                v-show='is_edit'
                required>
          </fg-input>

          <fg-input label="再次輸入密碼"
                type="password"
                name="confirm_password"
                data-vv-as="再次輸入密碼"
                class=''
                v-model="form.confirm_password"   
                v-validate="{ confirmed: 'password' }"
                :error="getError('confirm_password')"
                v-show='is_edit'
                required>
          </fg-input>
          <div class='row d-flex justify-content-between align-items-center' style='margin:80px 0px 0px'>
          <div style='font-size:18px;'>權限設定</div>
          </div>
          <hr>
         
          <div class="form-group has-label">
            <label> 權限狀態</label>          
            <div>
               <div v-show='!is_edit'>{{ getEnableText(form)}}</div>
              <l-switch v-show='is_edit' v-model="form.Enabled" on-text="ON" off-text="OFF" v-bind:disabled="!is_edit" ></l-switch>
            </div>
          </div>
          <div class='row d-flex justify-content-between align-items-center' style='margin:125px 0px 0px'>
            <div style='font-size:18px;'>標籤設定</div>
          </div>
          <hr>        
          <label style='margin:5px 5px;text-transform:none;' :key="`tagLabel${idx}`" v-for="(tag, idx) in form.tags"  v-show="!is_edit" >#{{tag.name}}</label>
          <template v-if="is_edit" >
            <div class='row d-flex justify-content-between align-items-center' style='margin:10px 0px' >
              <el-autocomplete v-model="tags.inputValue" :fetch-suggestions="querySearch" @select="handleSelect" style='width:70%' :trigger-on-focus="false" :highlight-first-item="true"></el-autocomplete>
              <button type="button" @click="handleInputConfirm()" class="btn btn-fill btn-info" >增加</button>
            </div>
            <el-tag v-bind:key="tag.name" v-for="(tag,idx) in form.tags" size="small" type="default" :closable="true" :close-transition="true" @close="handleClose(idx)" v-show="is_edit">
                  {{tag.name}}
            </el-tag>
          </template>
          </div>
          <hr>
      </div>
    </div>
  </div>
</template>
<script>
 /* eslint-disable */ 
  import { Dialog, Table, TableColumn, MessageBox, Select, Option, DatePicker, Tag, Autocomplete } from 'element-ui'
  import { Pagination as LPagination, ImageUpload,  Switch as LSwitch } from 'src/components/index'
  import lib from '../../../lib'
  import tools from '../../../tools'
  import swal from 'sweetalert2/dist/sweetalert2.js'
  import phoneData from '../../../config/phone.js'
  import PhoneNumber from 'awesome-phonenumber'

  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Dialog.name]: Dialog,
      [Table.name]: Table,
      [Autocomplete.name]: Autocomplete,
      [TableColumn.name]: TableColumn,
      [DatePicker.name]: DatePicker,
      [Tag.name]: Tag,
      LSwitch,
      LPagination,
      ImageUpload,

    },
    data () {
      return {
        pagination: {
          perPage: 20,
          currentPage: 1,
          total: 0
        },
        PaginationToken: null,
        form:{
          id: null,
          image: null,
          title: null,
          content: null,

        },
        searchForm:{
        },
        query: {
          search_type: null,
          search_text: '',
          countryCode: null
        },
        is_edit_page: false,
        is_edit: false,
        is_new: false,
        newTag:'',
        preferred_username_1: null,
        preferred_username_2: null,
        phone_number_1: null,
        phone_number_2: null,
        tableData: [],
        companies: [],
        phoneData: [],
        tagData:[],
         tags: {
            dynamicTags: [{name:'Tag 1'},{name: 'Tag 2'},{name: 'Tag 3'}],
            // inputVisible: false,
            inputValue: ''
          }
      }
    },
    async mounted () {
      await this.resetPage() 
    },
    watch:{
      // check_phone_number(value) {
      //   this.$validator.validate('check_phone_number', value);
      // }
    },
    created: function () {

      this.phoneData = phoneData
      this.$validator.extend('checkPhone', {
        getMessage: function (field) { 
          return '手機 格式有誤'; 
        },
        validate: function (value) {
          var pn = new PhoneNumber(value)
          return pn.isMobile()        
        }
      })

      this.$validator.extend('checkTwId', {
        getMessage: function (field) { 
          return '身分證 格式有誤'; 
        },
        validate: function (id) {
          let tab = "ABCDEFGHJKLMNPQRSTUVXYWZIO"                     
          let A1 = new Array (1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3)
          let A2 = new Array (0,1,2,3,4,5,6,7,8,9,0,1,2,3,4,5,6,7,8,9,0,1,2,3,4,5)
          let Mx = new Array (9,8,7,6,5,4,3,2,1,1);

          if ( id.length != 10 ) return false;
          let i = tab.indexOf( id.charAt(0) );
          if ( i == -1 ) return false;
          let sum = A1[i] + A2[i]*9;

          for (let j=1; j<10; j++ ) {
            let v = parseInt( id.charAt(j) );
            if ( isNaN(v) ) return false;
            sum = sum + v * Mx[j];
          }
          if ( sum % 10 != 0 ) return false;
          return true;
        }
      })

    },
    computed: {
      check_phone_number (){
        return `+${this.phone_number_1}${this.phone_number_2}`
      },
      getTitle (){
        return (!this.form.sub)?'新增會員':'編輯會員'
      },
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      queriedData () {
        let result = this.tableData
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      },
      getPasswordRule(){
        let rule = {}
        if (!Boolean(this.form.sub)){
          rule.required = true
        }
        rule.regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
        return rule
      },
      getIdRule(){
        let rule = {}
        if (!Boolean(this.form.sub)){
          rule.required = true
        }
        if (this.preferred_username_2 == '1'){
          rule.checkTwId = true
        }
        console.log(rule)
        return rule
      },
    },
    methods: {
      getCountryCodeForRegionCode: PhoneNumber.getCountryCodeForRegionCode,
      querySearch(query, cb){
        let data = this.tagData.filter(el => el.name.search(query) >= 0)
        if (this.form.tags){
          let names = (this.form.tags.map(({ name }) => name))
          data = data.filter(({ name }) => !names.find(el => el == name))
        }
        let output = []
        data.map(el => output.push({id: el.id, value: el.name}))
        cb(output)
      },
      handleSelect(item) {
        if (!this.form.tags) this.$set(this.form, 'tags', [])
        this.form.tags.push({id:item.id, name: item.value})
        this.tags.inputValue = ''
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      getEnableText(row){
        var is_enable = row.Enabled
        return (is_enable)?'ON':'OFF'
      },
      getPhoneNumber(row){
        var phone_number = row.phone_number
        var pn = new PhoneNumber(phone_number)
        return '+' + pn.getCountryCode() + '  ' + pn.getNumber( 'significant' )
      },
      getSubmitText(){
        return (!this.form.sub)?'確定新增':'儲存'
      },
      handleInputConfirm () {
        let inputValue = this.tags.inputValue
        if (inputValue) {
          if (!this.form.tags) this.$set(this.form, 'tags', [])
          if (!this.form.tags.find(el => el.name == inputValue)){
              this.form.tags.push({name: inputValue})
          }else{
             swal('重複的標籤', '', 'error')
          }
        }
        this.tags.inputVisible = false
        this.tags.inputValue = ''
      },
      handleClose (tag) {
       this.form.tags.splice(tag, 1)
        // this.tags.dynamicTags.splice(this.tags.dynamicTags.indexOf(tag), 1)
        // this.form.tags.splice(idx, 1)
        // console.log(JSON.parse(JSON.stringify(this.form.tags)))
      },
      validate () {
        var _this = this
        this.$validator.validateAll().then(isValid => {
          console.log(  this.$validator)
          if (!isValid) return;
          if (!this.form.sub){
            _this.create()
          } else{
            _this.update()
          }
        })
      },
      handleImageUpload(result, data){
        if (result){
          this.form.image = data
        }else{
          swal('上傳失敗', data, 'error')
        }
      },
      edit (){
        this.is_edit = true
      },
      editCancel (){
        this.is_edit = false
        if (this.origin) this.form = JSON.parse(JSON.stringify(this.origin))
      },
      handleChange(){
        if (this.query.search_type == 'phone_number') this.query.countryCode = "886"
        this.query.search_text = ''
      },
      async resetPage(){
          await this.getData()
          await this.getTags()
      },
      async handleIndexChange() {
        await this.getData()
      },
      async handleNew () {
        await this.clearForm()
        this.is_edit_page = true
        this.is_edit = true
        this.form = {"Enabled": true}
        this.phone_number_1 = "886"
        this.phone_number_2 = null
        this.preferred_username_1 = null
        this.preferred_username_2 = null
        this.$validator.errors.clear()
      },
      async handleEdit (row) {
        await this.clearForm()
        this.is_edit_page = true
        this.$validator.errors.clear()
        
        this.origin = JSON.parse(JSON.stringify(row))
        this.form = JSON.parse(JSON.stringify(row))
        var phone_number = row.phone_number
        var pn = new PhoneNumber(phone_number)
        this.phone_number_1 = pn.getCountryCode().toString()
        this.phone_number_2 = pn.getNumber( 'significant' )
      },
      async getTags(){
         var result = await lib.getTagList()
         if (result.code == 0){
           this.tagData = result.data
         }
      },
      async getData (is_more = false) {
        let query = {}
        if (this.query.search_type && this.query.search_text){
          query.search_type = this.query.search_type
          let search_text = this.query.search_text
          if (this.query.search_type == 'phone_number'){
            
            let phone_number = '+' + this.query.countryCode + search_text
            var pn = new PhoneNumber(phone_number, 'tw')
            if (!pn.isValid()) {
              swal('手機號碼格式錯誤，請重新填寫', '', 'error')
              return
            }
            search_text = pn.getNumber()
          }
          query.search_text = encodeURIComponent(search_text.toString())
        }
        query.limit = this.pagination.perPage
        if (is_more){
          if (this.PaginationToken == null) return
          query.PaginationToken = encodeURIComponent(this.PaginationToken)
        }
        var result = await lib.getMemberList(query)
        if (result.code == 0){
          var data = result.data
          if (is_more){
            this.tableData = this.tableData.concat(data)
            this.pagination.currentPage++
          }else{
            this.tableData = data 
          }
          this.PaginationToken = (result.pages)?result.pages.PaginationToken:null
        }
      },
      async clearForm(){
        var _this = this
        return new Promise(function(resolve, reject){  
            _this.form.sub = ''
          resolve()
        })
      },
      async create(){
        this.form.preferred_username = `${this.preferred_username_1}-${this.preferred_username_2}`
        this.form.phone_number = `+${this.phone_number_1}${this.phone_number_2}`
        var result = await lib.createMember(this.form);
        if (result.code == 0){
          await this.getData()
          await this.getTags()
          this.is_edit = false
          this.is_edit_page = false
          swal('新增成功', '', 'success')
        }else {
          let msg = '新增失敗'
          if (result.code == 3) msg = '身份重複'
          if (result.data){
            if (result.data.code == 'UsernameExistsException') msg = '此手機已註冊過'
             swal(msg, '', 'error')
             return
          }
          swal(msg, JSON.stringify(result.data), 'error')
        }
      },
      async update(){
        var result = await lib.updateMember(this.form.sub, this.form);
        if (result.code == 0){
          await this.getData()
          await this.getTags()
          this.is_edit = false
          this.is_edit_page = false
          swal('已更新', '', 'success')
        }else {
          swal('更新失敗', '', 'error')
        }
      },
      async handleDelete(id, porp){
        var confirm = await tools.confirmDialog("請確認要刪除?");
          if (confirm){
            var result = await lib.deleteUser(id)
            if (result.code == 0){
              this.pagination.currentPage = 1
              this.is_edit = false
              this.is_edit_page = false
              await this.getData()
              swal('已刪除', '', 'success')
            }else{
              swal('刪除失敗', '', 'error')
            }
        }
      }
    }
  }
</script>
<style>
.toolsBar{
  justify-content: space-between;
  display: flex;
}

.leftTools{
  display: flex;
}

.leftTools > *{
  padding-right: 10px;
}
</style>
