<template>
  <div>
    <div align='right' class="mt-3" v-if='isEditing'>
      <l-button size="sm" type="primary" @click="save()" class="px-2">Save</l-button>
      <l-button size="sm" type="default" outline @click="cancel()" class="ml-3 px-2">Cancel</l-button>
    </div>
    <div align='right' class="mb-3" v-if='!isEditing'>
      <l-button size="sm" type="primary" @click="edit()" class="px-2">Edit</l-button>
      <l-button size="sm" type="default" outline @click="back()" class="ml-3 px-2">Back</l-button>
    </div>

    <div v-if="form">
      <div class="row">
        <div class="col">
          <p class="mb-1"><b>Patient Source</b></p>
          <fg-input
            type="text"
            name="patient_source"
            data-vv-as="Patient Source"
            class='input-disabled-text-black'
            v-model="form.patient_source"
            :error="getError('patient_source')"
            :readonly="!isEditing" />
        </div>
        <div class="col">
          <p class="mb-1"><b>Date of examination</b></p>
          <fg-input
            type="text"
            name="test_date"
            data-vv-as="Date of examination"
            class='input-disabled-text-black'
            v-model="form.test_date"
            :error="getError('test_date')"
            :readonly="!isEditing" />
          </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="mb-1"><b>Patient Bed Number</b></p>
          <fg-input
            type="text"
            name="bed_id"
            data-vv-as="Patient Bed Number"
            class='input-disabled-text-black'
            v-model="form.bed_id"
            :error="getError('bed_id')"
            :readonly="!isEditing" />
        </div>
        <div class="col">
          <p class="mb-1"><b>Date of receipt</b></p>
          <fg-input
            type="text"
            name="receive_datetime"
            data-vv-as="Date of receipt"
            class='input-disabled-text-black'
            v-model="form.receive_datetime"
            :error="getError('receive_datetime')"
            :readonly="!isEditing" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="mb-1"><b>Doctor（Order）</b></p>
          <fg-input
            type="text"
            name="medical_order_dr"
            data-vv-as="Doctor（Order）"
            class='input-disabled-text-black'
            v-model="form.medical_order_dr"
            :error="getError('medical_order_dr')"
            :readonly="!isEditing" />
        </div>
        <div class="col">
          <p class="mb-1"><b>Date of report</b></p>
          <fg-input
            type="text"
            name="report_datetime"
            data-vv-as="Date of report"
            class='input-disabled-text-black'
            v-model="form.report_datetime"
            :error="getError('report_datetime')"
            :readonly="!isEditing" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="mb-1"><b>Department</b></p>
          <fg-input
            type="text"
            name="division"
            data-vv-as="Department"
            class='input-disabled-text-black'
            v-model="form.division"
            :error="getError('division')"
            :readonly="!isEditing" />
        </div>
        <div class="col">
          <p class="mb-1"><b>Date of Test</b></p>
          <fg-input
            type="text"
            name="collection_datetime"
            data-vv-as="Date of Test"
            class='input-disabled-text-black'
            v-model="form.collection_datetime"
            :error="getError('collection_datetime')"
            :readonly="!isEditing" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="mb-1"><b>Test Group</b></p>
          <fg-input
            type="text"
            name="test_group"
            data-vv-as="Specimen Group"
            class='input-disabled-text-black'
            v-model="form.test_group"
            :error="getError('test_group')"
            :readonly="!isEditing" />
        </div>
        <div class="col">
          <p class="mb-1"><b>Test Item</b></p>
          <fg-input
            type="text"
            name="test_item"
            data-vv-as="Test Item"
            class='input-disabled-text-black'
            v-model="form.test_item"
            :error="getError('test_item')"
            :readonly="!isEditing" />
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <p class="mb-1"><b>Test Description</b></p>
          <fg-input
            type="text"
            name="test_description"
            data-vv-as="Test Description"
            class='input-disabled-text-black'
            v-model="form.test_description"
            :error="getError('test_description')"
            :readonly="!isEditing" />
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <p class="mb-1"><b>Daily urine volume</b></p>
          <fg-input
            type="text"
            name="urine_volume"
            data-vv-as="Daily urine volume"
            class='input-disabled-text-black'
            v-model="form.urine_volume"
            :error="getError('urine_volume')"
            :readonly="!isEditing" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="mb-1"><b>White Blood Cell Screening</b></p>
          <fg-input
            type="text"
            name="white_blood_cell_screen"
            data-vv-as="White Blood Cell Screening"
            class='input-disabled-text-black'
            v-model="form.white_blood_cell_screen"
            :error="getError('white_blood_cell_screen')"
            :readonly="!isEditing" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="mb-1"><b>Purpose of the examination and summary</b></p>
          <el-input class="mb-3 input-disabled-text-black" type="textarea" name="exam_purpose" v-model="form.exam_purpose" rows="2" data-vv-as="Purpose of the examination and summary" v-validate="'max:6800'" :disabled="!isEditing" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="mb-1"><b>Test Result Description</b></p>
          <el-input class="mb-3 input-disabled-text-black" type="textarea" name="result_description" v-model="form.result_description" rows="18" data-vv-as="Test Result Description" v-validate="'max:6800'" :disabled="!isEditing" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
 /* eslint-disable */ 


export default {
  props: ['data'],
  data () {
    return {
      isEditing: false,
      form: null,
    }
  },
  components: {
  },
  mounted() {
    this.createForm()
  },
  methods:{
    createForm() {
      this.form = {}
      this.form.patient_source = this.data.patient_source
      this.form.bed_id = this.data.bed_id
      this.form.medical_order_dr = this.data.medical_order_dr
      this.form.division = this.data.division
      this.form.test_group = this.data.test_group
      this.form.test_item = this.data.test_item
      this.form.test_description = this.data.test_description
      this.form.urine_volume = this.data.urine_volume
      this.form.white_blood_cell_screen = this.data.white_blood_cell_screen
      this.form.exam_purpose = this.data.exam_purpose
      this.form.result_description = this.data.result_description
      this.form.test_date = this.data.test_date
      this.form.receive_datetime = this.data.receive_datetime
      this.form.report_timestamp = this.data.report_timestamp
      this.form.collection_datetime = this.data.collection_datetime
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    save() {
      this.isEditing = false
    },
    cancel() {
      this.createForm()
      this.isEditing = false
    },
    edit() {
      this.isEditing = true
    },
    back() {
      this.$emit('back')
    },
    groupClicked(id) {
      console.log(id)
    }
  }
}
</script>
<style>
.btn {
  justify-content: left !important;
}

.btn-link:focus {
  border-radius: 0;
  border-left-color: #888888 !important;
  background-color: white !important;
}

.highlight {
  color: #23CCEF !important;
}
</style>
