<template>
  <div>
    <div align='right' class="mt-3" v-if='isEditing'>
      <l-button size="sm" type="primary" @click="save()" class="px-2">Save</l-button>
      <l-button size="sm" type="default" outline @click="cancel()" class="ml-3 px-2">Cancel</l-button>
    </div>
    <div align='right' class="mb-3" v-if='!isEditing'>
      <l-button size="sm" type="primary" @click="edit()" class="px-2">Edit</l-button>
      <l-button size="sm" type="default" outline @click="back()" class="ml-3 px-2">Back</l-button>
    </div>

    <div>
      <div class="row" v-if='form'>
        <div class="col-12">
          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Subjective Data</b></p>
            </div>
            <div :class="valueColumnClass">
              <el-input class="input-disabled-text-black" type="textarea" name="subjectiveData" v-model="form.s_part" rows="6" data-vv-as="Subjective Data" v-validate="'max:6800'" :disabled="!isEditing" />
            </div>
          </div>

          <div class="row mt-3">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Objective Data</b></p>
            </div>
            <div :class="valueColumnClass">
              <el-input class="input-disabled-text-black" type="textarea" name="objectiveData" v-model="form.o_part" rows="6" data-vv-as="Objective Data" v-validate="'max:6800'" :disabled="!isEditing" />
            </div>
          </div>

          <div class="row mt-3">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Assessment</b></p>
            </div>
            <div :class="valueColumnClass">
              <el-input class="input-disabled-text-black" type="textarea" name="assessment" v-model="form.a_part" rows="6" data-vv-as="Assessment" v-validate="'max:6800'" :disabled="!isEditing" />
            </div>
          </div>

          <div class="row mt-3">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Plan</b></p>
            </div>
            <div :class="valueColumnClass">
              <el-input class="input-disabled-text-black" type="textarea" name="plan" v-model="form.p_part" rows="6" data-vv-as="Plan" v-validate="'max:6800'" :disabled="!isEditing" />
            </div>
          </div>

          
        </div>
      </div>
    </div>

  </div>
</template>
<script>
 /* eslint-disable */ 


import lib from '../../../lib'
import Vue from "vue"


export default {
  props: ['data'],
  data () {
    return {
      isEditing: false,
      form: null,
      titleColumnClass: 'col-md-2',
      valueColumnClass: 'col-md-10',
    }
  },
  components: {
  },
  async mounted() {
    this.createForm()
  },
  methods: {
    createForm() {
      this.form = {}
      this.form.s_part = this.data.s_part
      this.form.o_part = this.data.o_part
      this.form.a_part = this.data.a_part
      this.form.p_part = this.data.p_part
    },
    // async getInfos(){
    //   var result = await lib.generalInfoListOfUser(this.data.user_id, this.pagination.currentPage - 1)
    //   if (result.status == Vue.Constants.HttpStatus.Success) {
    //     var data = result.data
    //     this.infos = data.data

    //     this.pagination.perPage = data.page_size
    //     this.pagination.currentPage=  data.page + 1
    //     this.pagination.total = data.total_size
    //   }
    // },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    save() {
      this.isEditing = false
    },
    cancel() {
      this.createForm()
      this.isEditing = false
    },
    edit() {
      this.isEditing = true
    },
    back() {
      this.$emit('back')
    },
  }
}
</script>
