<template>
  <div>
    <div>
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Subjective Data</b></p>
            </div>
            <div :class="valueColumnClass">
              <pre>{{data.s_part}}</pre>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Objective Data</b></p>
            </div>
            <div :class="valueColumnClass">
              <pre>{{data.o_part}}</pre>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Assignment</b></p>
            </div>
            <div :class="valueColumnClass">
              <pre>{{data.a_part}}</pre>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Plan</b></p>
            </div>
            <div :class="valueColumnClass">
              <pre>{{data.p_part}}</pre>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div align='right'>
      <l-button size="sm" type="default" outline @click="back()" class="px-2">Back</l-button>
    </div>
  </div>
</template>
<script>
 /* eslint-disable */ 


export default {
  props: ['data'],
  data () {
    return {
      titleColumnClass: 'col-md-2',
      valueColumnClass: 'col-md-10',
    }
  },
  components: {
  },
  async mounted() {
  },
  methods:{
    back() {
      this.$emit('back')
    },
  }
}
</script>
