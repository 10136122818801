<template>
  <div>
    <div class='row mb-3'>
      <div class="col-md-12">
        <div class="float-right">
          <button type="submit" @click.prevent="validate" class="btn btn-fill btn-primary ml-3">Add</button>
          <button type="button" @click="cancel()" class="btn btn-outline ml-3">Cancel</button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md" v-if='form'>
        <card>
          <h3>Personal Document</h3>
          <hr/>
          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Display Name</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                type="text"
                name="displayName"
                data-vv-as="Display Name"
                class=''
                v-model="form.displayName"
                v-validate="{ required: true }"
                :error="getError('displayName')"
                />
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Identity Card</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                type="text"
                name="identity"
                data-vv-as="Identity Card"
                class=''
                v-model="form.identity"
                v-validate="{ required: true }"
                :error="getError('identity')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Contact Phone</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                type="text"
                name="mobile"
                data-vv-as="Contact Phone"
                class=''
                v-model="form.mobile"
                v-validate="{ required: true }"
                :error="getError('mobile')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Email</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                type="email"
                name="email"
                data-vv-as="Email"
                class=''
                v-model="form.email"
                v-validate="'required|email'"
                :error="getError('email')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>First Name</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                type="text"
                name="firstName"
                data-vv-as="First Name"
                class=''
                v-model="form.firstName"
                v-validate="{ required: true }"
                :error="getError('firstName')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Last Name</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                type="text"
                name="lastName"
                data-vv-as="Last Name"
                class=''
                v-model="form.lastName"
                v-validate="{ required: true }"
                :error="getError('lastName')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Hospital</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                type="text"
                name="hostipal"
                data-vv-as="Hospital"
                class=''
                v-model="form.hospital"
                v-validate="{ required: true }"
                :error="getError('hostipal')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Title</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                type="text"
                name="title"
                data-vv-as="Title"
                class=''
                v-model="form.title"
                v-validate="{ required: true }"
                :error="getError('title')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Department</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                type="text"
                name="department"
                data-vv-as="Department"
                class=''
                v-model="form.department"
                v-validate="{ required: true }"
                :error="getError('department')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Specialist</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                type="text"
                name="specialists"
                data-vv-as="Specialist"
                class=''
                v-model="form.specialists"
                v-validate="{ required: true }"
                :error="getError('specialists')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Phone Number</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                type="text"
                name="phone"
                data-vv-as="Phone Number"
                class=''
                v-model="form.phone"
                v-validate="{ required: true }"
                :error="getError('phone')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Gender</b></p>
            </div>
            <div :class="valueColumnClass">
              <el-select class="select-default mb-3" v-model="genderModel" :close-transition="true">
                <el-option value='0' label="Unknown" />
                <el-option value='1' label="Male" />
                <el-option value='2' label="Female" />
              </el-select>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Birthday</b></p>
            </div>
            <div :class="valueColumnClass">
              <el-date-picker
                class="mb-3"
                v-model="form.birthday"
                type="date"
                placeholder="Pick a day"
                value-format='timestamp'
                format="yyyy/MM/dd"
                >
              </el-date-picker>
            </div>
          </div>
        </card>
      </div>

      <div class="col-md" v-if='form'>
        <card>
          <h3>Password Setting</h3>
          <hr/>
          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Password</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                type="password"
                name="newPassword"
                data-vv-as="Password"
                class=''
                v-model="newPassword"
                v-validate="getPasswordRule"
                :error="getError('newPassword')"/>
            </div>
          </div>
          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Confirm</b></p>
            </div>
            <!-- v-validate="{ required: true }" -->
            <div :class="valueColumnClass">
              <fg-input
                type="password"
                name="confirmNewPassword"
                data-vv-as="Confirm"
                class=''
                v-model="confirmNewPassword"
                v-validate="getPasswordRule"
                :error="getError('confirmNewPassword')"/>
            </div>
          </div>
        </card>

        <card>
          <h3>Permission</h3>
          <hr/>
          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Status</b></p>
            </div>
            <div :class="valueColumnClass">
              <l-switch class="my-auto" v-model="form.status" on-text="ON" off-text="OFF"></l-switch>
            </div>
          </div>
        </card>
      </div>
    </div>

    
    
  </div>
</template>
<script>
  import { Dialog, Table, TableColumn, MessageBox, Select, Option, DatePicker, Tag, Autocomplete } from 'element-ui'
  import { Checkbox as LCheckbox, FadeRenderTransition, Switch as LSwitch } from 'src/components/index'
  import Card from '../../../components/Cards/Card.vue'
  import sweetAlert from 'sweetalert2/dist/sweetalert2.js'
  import lib from '../../../lib.js'
  import Vue from "vue"

  export default {
    props: ['userId'],
    components: {
      [DatePicker.name]: DatePicker,
      [Select.name]: Select,
      [Option.name]: Option,
      LCheckbox,
      FadeRenderTransition,
      LSwitch
    },
    Card,
    data() {
      return {
        titleColumnClass: 'col-md-3',
        valueColumnClass: 'col-md-9',
        form: null,
        showAlert: false,
        newPassword: null,
        confirmNewPassword: null
      }
    },
    beforeMount() {
    },
    async mounted () {
      // await this.getUser()
      this.form = {
        status: true,
        gender: 0
      }
    },
    computed: {
      genderModel: {
        get: function() {
          return this.getGenderText(this.form.gender)
        },
        set: function(newValue) {
          console.log('newValue:', newValue)
          console.log('typeof newValue:', typeof newValue)
          switch (newValue) {
            case '0':
              console.log('0')
              this.form.gender = 0
              break
            case '1':
              console.log('1')
              this.form.gender = 1
              break
            case '2':
              console.log('2')
              this.form.gender = 2
              break
            default:
              console.log('d')
              this.form.gender = 0
              break
          }
        }
      },
      statusModel: {
        get: function() {
          const v = this.getStatusText(this.form.status)
          return v
        },
        set: function(newValue) {

          switch (newValue) {
            case '0':
              this.form.status = false
              break
            case '1':
              this.form.status = true
              break
            default:
              break
          }
        }
      },
      getPasswordRule(){
        let rule = {}
        rule.required = true
        rule.regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
        return rule
      }
    },
    methods: {
      cancel() {
        this.$emit('back')
      },
      // async getUser() {
      //   const result = await lib.getUserById(this.userId)
      //   if (result.status == Vue.Constants.HttpStatus.Success) {
      //     const user = result.data.data
      //     this.user = user

      //     this.adminGroups = []
      //     this.memberGroups = []
      //     for (let group of user.groups) {
      //       if (group.admin == user.id) {
      //         this.adminGroups.push(group)
      //       } else {
      //         this.memberGroups.push(group)
      //       }
      //     }
      //   } else {
      //     if (result.data.message) {
      //       sweetAlert.fire({
      //         type: 'error',
      //         text: result.data.message
      //       })
      //     }
      //   }
      // },
      async createUser() {
        const result = await lib.createUser(this.form)
        if (result.status == Vue.Constants.HttpStatus.Success) {
          sweetAlert.fire({
            type: 'success',
            text: result.data.message
          }).then((result) => {
            this.$emit('back')
          })
        } else {
          if (result.data.message) {
            sweetAlert.fire({
              type: 'error',
              text: result.data.message
            })
          }
        }
      },
      getGenderText(value) {
        console.log('value:', value)
        console.log('typeof value:', typeof value)
        switch (value) {
          case 0:
            console.log('0')
            return "Unknown"
          case 1:
            console.log('1')
            return "Male"
          case 2:
            console.log('2')
            return "Female"
          default:
            console.log('d')
            return ""
        }
      },
      getBirthdayText(value) {
        const date = new Date(value)
        return this.formatDate(date)
      },
      formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('/');
      },
      getStatusText(value) {
        return (value) ? 'ON' : 'OFF'
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      back() {
        this.$emit('back')
      },
      validate() {
        var _this = this
        this.$validator.validateAll().then(isValid => {
          if (!isValid) return

          if (this.newPassword || this.confirmNewPassword) {
            if (this.newPassword != this.confirmNewPassword) {
              sweetAlert.fire({
                type: 'error',
                text: 'Two new passwords are not identical.'
              })
              return
            } else {
              this.form.password = this.newPassword
            }
          }

          _this.createUser()
        })
      }
    },
    beforeDestroy () {
    }
  }
</script>
