<template>
  <div>
    <div align='right' class="mt-3" v-if='isEditing'>
      <l-button size="sm" type="primary" @click="save()" class="px-2">Save</l-button>
      <l-button size="sm" type="default" outline @click="cancel()" class="ml-3 px-2">Cancel</l-button>
    </div>
    <div align='right' class="mb-3" v-if='!isEditing'>
      <l-button size="sm" type="primary" @click="edit()" class="px-2">Edit</l-button>
      <l-button size="sm" type="default" outline @click="back()" class="ml-3 px-2">Back</l-button>
    </div>

    <div v-if="form">
      <div class="row">
        <div class="col">
          <p class="mb-1"><b>OR#</b></p>
          <fg-input
            type="text"
            name="orNumber"
            data-vv-as="OR#"
            class='input-disabled-text-black'
            v-model="form.orNumber"
            :error="getError('orNumber')"
            :readonly="!isEditing" />
        </div>
        <div class="col">
          <p class="mb-1"><b>Entry Time</b></p>
          <fg-input
            type="text"
            name="entryTime"
            data-vv-as="Entry Time"
            class='input-disabled-text-black'
            v-model="form.entryTime"
            :error="getError('entryTime')"
            :readonly="!isEditing" />
          </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="mb-1"><b>Proceduce Number</b></p>
          <fg-input
            type="text"
            name="proceduceNumber"
            data-vv-as="Proceduce Number"
            class='input-disabled-text-black'
            v-model="form.proceduceNumber"
            :error="getError('proceduceNumber')"
            :readonly="!isEditing" />
        </div>
        <div class="col">
          <p class="mb-1"><b>Start of anesthesia</b></p>
          <fg-input
            type="text"
            name="anesthesiaStart"
            data-vv-as="Start of anesthesia"
            class='input-disabled-text-black'
            v-model="form.anesthesiaStart"
            :error="getError('anesthesiaStart')"
            :readonly="!isEditing" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="mb-1"><b>Source</b></p>
          <fg-input
            type="text"
            name="source"
            data-vv-as="Source"
            class='input-disabled-text-black'
            v-model="form.source"
            :error="getError('source')"
            :readonly="!isEditing" />
        </div>
        <div class="col">
          <p class="mb-1"><b>Preparation start</b></p>
          <fg-input
            type="text"
            name="preparationStart"
            data-vv-as="Preparation start"
            class='input-disabled-text-black'
            v-model="form.preparationStart"
            :error="getError('preparationStart')"
            :readonly="!isEditing" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="mb-1"><b>Bed Number</b></p>
          <fg-input
            type="text"
            name="bedNumber"
            data-vv-as="Bed Number"
            class='input-disabled-text-black'
            v-model="form.bedNumber"
            :error="getError('bedNumber')"
            :readonly="!isEditing" />
        </div>
        <div class="col">
          <p class="mb-1"><b>Start of incision</b></p>
          <fg-input
            type="text"
            name="incisionStart"
            data-vv-as="Start of incision"
            class='input-disabled-text-black'
            v-model="form.incisionStart"
            :error="getError('incisionStart')"
            :readonly="!isEditing" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="mb-1"><b>Date of registratio</b></p>
          <fg-input
            type="text"
            name="registerDate"
            data-vv-as="Date of registratio"
            class='input-disabled-text-black'
            v-model="form.registerDate"
            :error="getError('registerDate')"
            :readonly="!isEditing" />
        </div>
        <div class="col">
          <p class="mb-1"><b>End time</b></p>
          <fg-input
            type="text"
            name="endTime"
            data-vv-as="End time"
            class='input-disabled-text-black'
            v-model="form.endTime"
            :error="getError('endTime')"
            :readonly="!isEditing" />
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <p class="mb-1"><b>Send out of room</b></p>
          <fg-input
            type="text"
            name="sendOutOfRoom"
            data-vv-as="Send out of room"
            class='input-disabled-text-black'
            v-model="form.sendOutOfRoom"
            :error="getError('sendOutOfRoom')"
            :readonly="!isEditing" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="mb-1"><b>Surgical Record or Surgical Report</b></p>
          <el-input class="mb-3 input-disabled-text-black" type="textarea" name="surgeryContent" v-model="form.surgeryContent" rows="18" data-vv-as="Surgical Record or Surgical Report" v-validate="'max:6800'" :disabled="!isEditing" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
 /* eslint-disable */ 


export default {
  props: ['data'],
  data () {
    return {
      isEditing: false,
      form: null,
    }
  },
  components: {
  },
  mounted() {
    this.createForm()
  },
  methods:{
    createForm() {
      this.form = {}
      this.form.orNumber = this.data.orNumber
      this.form.entryTime = this.data.entryTime
      this.form.proceduceNumber = this.data.proceduceNumber
      this.form.anesthesiaStart = this.data.anesthesiaStart
      this.form.source = this.data.source
      this.form.preparationStart = this.data.preparationStart
      this.form.bedNumber = this.data.bedNumber
      this.form.incisionStart = this.data.incisionStart
      this.form.registerDate = this.data.registerDate
      this.form.endTime = this.data.endTime
      this.form.sendOutOfRoom = this.data.sendOutOfRoom
      this.form.surgeryContent = this.data.surgeryContent
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    save() {
      this.isEditing = false
    },
    cancel() {
      this.createForm()
      this.isEditing = false
    },
    edit() {
      this.isEditing = true
    },
    back() {
      this.$emit('back')
    },
    groupClicked(id) {
      console.log(id)
    }
  }
}
</script>
<style>
.btn {
  justify-content: left !important;
}

.btn-link:focus {
  border-radius: 0;
  border-left-color: #888888 !important;
  background-color: white !important;
}

.highlight {
  color: #23CCEF !important;
}
</style>
